import { useMemo, useState } from "react";
import classes from "../ManegementForms.module.scss";
import FieldEditItemInfo from "components/ui/field-edit-item-info/FieldEditItemInfo";
import InputAuto from "components/ui/input-autocomplite/InputAutocomplite";

export interface ISelectedType<T> {
  label: string;
  data?: T;
  value: string;
  text?: string;
  isHideEdit?: boolean;
  isHideDelete?: boolean;
}

export const MOCK_LIST_ONE = [
  { label: "Project-менеджмент", data: "1", value: "1" },
  { label: "Back разработка", data: "2", value: "2" },
  { label: "PR-менеджмент", data: "3", value: "3" },
  { label: "HR-менеджмент", data: "4", value: "4" },
  { label: "Лаврова Ирина", data: "5", value: "5" },
  { label: "Дизайн", data: "6", value: "6" },
];

export const MOCK_LIST_TWO = [
  { label: "Java script", name: "1", value: "1" },
  { label: "React", name: "2", value: "2" },
  { label: "CSS", name: "3", value: "3" },
  { label: "PHP", name: "4", value: "4" },
  { label: "Figma", name: "5", value: "5" },
  { label: "Adobe Photoshop", name: "6", value: "6" },
];

const FormColumn = <T extends { id: number }>({
  title,
  data,
  dataAutocomplite = [],
  onDeleteField,
  onSaveItem,
  onClickItem,
  activeItem,
  onCreateField,
  sortListOff = false,
  selected = [],
  autocomplite,
  onAutocompliteEvents,
  readonlyName,
  disabled,
}: {
  title: string;
  data: ISelectedType<T>[];
  dataAutocomplite?: ISelectedType<T>[];
  onDeleteField: (value: T, path?: string) => void;
  onSaveItem?: (title: string, value: string, data?: T) => void;
  onClickItem?: React.Dispatch<React.SetStateAction<T | null>>;
  activeItem?: T | null;
  onCreateField?: (value: string) => void;
  sortListOff?: boolean;
  selected?: ISelectedType<T>[];
  autocomplite?: boolean;
  onAutocompliteEvents?: (
    type: "add" | "sub" | "clear",
    value?: null | ISelectedType<T>,
  ) => void;
  readonlyName?: boolean;
  disabled?: boolean;
}) => {
  const [search, setSearch] = useState("");

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearch(e.target.value);
    } else {
      setSearch("");
    }
  };

  const onSearchActive = (
    type: "add" | "sub" | "clear",
    value?: null | ISelectedType<T>,
  ) => {
    onAutocompliteEvents && onAutocompliteEvents(type, value);
    setSearch("");
  };

  const onCreate = (value: string) => {
    onCreateField && onCreateField(value);
    setSearch("");
  };

  const onClickField = onClickItem
    ? (data: T) => {
        if (activeItem) {
          onClickItem(
            activeItem.id.toString() === data.id.toString() ? null : data,
          );
        } else {
          onClickItem(data);
        }
      }
    : undefined;

  const searchList = useMemo(() => {
    if (data.length === 0 || search === "" || sortListOff) {
      return data;
    }
    return data.filter((item) =>
      item.label.toUpperCase().includes(search.toUpperCase()),
    );
  }, [search, data, activeItem, onClickField, sortListOff]);

  const suggestionsList = useMemo(() => {
    if (dataAutocomplite.length === 0 || search === "") {
      return dataAutocomplite;
    }

    return dataAutocomplite.filter((item) =>
      item.label.toUpperCase().includes(search.toUpperCase()),
    );
  }, [search, dataAutocomplite, activeItem, onClickField]);

  return (
    <div className={classes.col}>
      <h3 className={classes.subtitle}>{title}</h3>

      <InputAuto<T>
        placeholder="Начните ввод"
        selected={selected}
        suggestions={suggestionsList}
        search={search}
        onChange={onSearch}
        onSelected={onSearchActive}
        onCreate={onCreateField ? onCreate : undefined}
        autocomplite={autocomplite}
        disabled={disabled}
      />

      <ul className={classes.list}>
        {searchList.map((item, index) => (
          <li
            key={
              index +
              "-" +
              (activeItem?.id || 0) +
              (item?.data?.id ? "-" + item?.data?.id : "")
            }
          >
            <FieldEditItemInfo
              active={item.data?.id === activeItem?.id && activeItem !== null}
              title={item.label}
              onDelete={() => {
                if (item.data) {
                  onDeleteField(item.data);
                }
              }}
              onEditSave={
                onSaveItem
                  ? (title, value) => {
                      onSaveItem(title, value, item.data);
                    }
                  : undefined
              }
              onClick={
                onClickField && item?.data
                  ? () => onClickField(item.data!)
                  : undefined
              }
              link={item.text}
              offEdit={item.isHideEdit}
              offDelete={item.isHideDelete}
              readonlyName={readonlyName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FormColumn;
