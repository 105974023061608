import { useState, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { getMonth, getYear, isSameISOWeek } from "date-fns";
import classes from "./DashboardSmallCalendar.module.scss";
import { rangeNumber } from "helpers/functions";

import { ru } from "date-fns/locale";

registerLocale("ru", ru);

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const DashboardSmallCalendar = ({
  date,
  onChangeDate,
  modalOff = false,
}: {
  date: Date | null;
  onChangeDate: (value: Date | null) => void;
  modalOff?: boolean;
}) => {
  const currentDate = date || new Date();
  const [month, setMonth] = useState<string>(months[currentDate.getMonth()]);
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const refCalendarWrap = useRef<null | HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const onClickNavgationCalendar = (nav: "next" | "prev") => {
    if (refCalendarWrap && refCalendarWrap.current) {
      const mmEl =
        refCalendarWrap.current.querySelector<HTMLSelectElement>(
          ".selectMonth",
        );
      const yearEl =
        refCalendarWrap.current.querySelector<HTMLSelectElement>(
          ".refSelectYear",
        );
      let mm = months.findIndex((item) => item === mmEl?.value);
      let year = yearEl ? parseInt(yearEl?.value) : currentDate.getFullYear();

      if (nav === "prev") {
        const prev =
          refCalendarWrap.current.querySelector<HTMLButtonElement>(
            ".refBtnPrevMonth",
          );
        prev?.click();
        if (mm <= 0) {
          mm = 11;
          year--;
        } else {
          mm--;
        }
      } else {
        const next =
          refCalendarWrap.current.querySelector<HTMLButtonElement>(
            ".refBtnNextMonth",
          );
        next?.click();

        if (mm === 11) {
          mm = 0;
          year++;
        } else {
          mm++;
        }
      }

      setMonth(months[mm]);
      setYear(year);
      onChangeDate(new Date(`${year}-${mm + 1}-${currentDate.getDay() + 1}`));
    }
  };

  const onChange = (values: Date | null) => {
    onChangeDate(values);
    setOpen(false);
  };

  return (
    <div className={classes.dashboarHeaderActionsCalendar}>
      <div className={classes.dashboarHeaderActionsCalendarContent}>
        <button type="button" onClick={() => onClickNavgationCalendar("prev")}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-0.75"
              y="0.75"
              width="14.5"
              height="14.5"
              rx="7.25"
              transform="matrix(-1 0 0 1 15 0.5)"
              stroke="#E2E2E2"
              strokeWidth="1.5"
            />
            <path
              d="M10.1001 5.30005L6.9001 8.50005L10.1001 11.7001"
              stroke="#E2E2E2"
              strokeWidth="1.5"
            />
          </svg>
        </button>
        <div
          className={`${classes.dashboarHeaderActionsCalendarTitle} ${
            open ? classes.dashboarHeaderActionsCalendarOpen : ""
          }`}
          onClick={modalOff ? undefined : () => setOpen((prev) => !prev)}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.5 8.83325H15.9667V12.8333C15.9667 14.6742 14.4594 16.1666 12.6 16.1666H5.86667C4.00731 16.1666 2.5 14.6742 2.5 12.8333V8.83325Z"
              stroke="#999999"
              strokeWidth="1.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.5 8.83325H15.9667V12.8333C15.9667 14.6742 14.4594 16.1666 12.6 16.1666H5.86667C4.00731 16.1666 2.5 14.6742 2.5 12.8333V8.83325Z"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="1.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.84667 4.83325H14.62C15.3637 4.83325 15.9667 5.43021 15.9667 6.16659V8.83325H2.5V6.16659C2.5 5.43021 3.10292 4.83325 3.84667 4.83325Z"
              stroke="#999999"
              strokeWidth="1.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.84667 4.83325H14.62C15.3637 4.83325 15.9667 5.43021 15.9667 6.16659V8.83325H2.5V6.16659C2.5 5.43021 3.10292 4.83325 3.84667 4.83325Z"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="1.5"
            />
            <path
              d="M13.2729 3.5V5.5"
              stroke="#999999"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.2729 3.5V5.5"
              stroke="#999999"
              strokeOpacity="0.2"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.86669 3.5V5.5"
              stroke="#999999"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.86669 3.5V5.5"
              stroke="#999999"
              strokeOpacity="0.2"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {month} {year}
        </div>
        <button type="button" onClick={() => onClickNavgationCalendar("next")}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="1.25"
              y="1.25"
              width="14.5"
              height="14.5"
              rx="7.25"
              stroke="#E2E2E2"
              strokeWidth="1.5"
            />
            <path
              d="M6.8999 5.30005L10.0999 8.50005L6.8999 11.7001"
              stroke="#E2E2E2"
              strokeWidth="1.5"
            />
          </svg>
        </button>
      </div>
      <div
        ref={refCalendarWrap}
        className={`${classes.dashboarCalendar} ${
          open ? classes.dashboarCalendarOpen : classes.dashboarCalendarClose
        }`}
      >
        <DatePicker
          calendarClassName="calendar-dashboard"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                justifyContent: "center",
                display: "none",
              }}
            >
              <button
                className={"refBtnPrevMonth"}
                onClick={() => {
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                className="refSelectYear"
                onChange={({ target: { value } }) =>
                  changeYear(parseInt(value))
                }
              >
                {rangeNumber(2023, date.getFullYear() + 1).map(
                  (option: number) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ),
                )}
              </select>

              <select
                className="selectMonth"
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => {
                  changeMonth(months.indexOf(value));
                }}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                className={"refBtnNextMonth"}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
          onChange={onChange}
          onYearChange={(_date) => setYear(_date.getFullYear())}
          onClickOutside={() => {
            setOpen(false);
          }}
          locale="ru"
          calendarStartDay={1}
          dayClassName={(day: Date) => {
            return isSameISOWeek(day, currentDate)
              ? "react-datepicker__day--selected custom"
              : "";
          }}
          inline
        />
      </div>
    </div>
  );
};
