import { useContext, useRef, useState } from "react";
import { ApiDataType, FormDataType, IManagementEmployeFull } from "types";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";
import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import { UserInfoContext } from "context/UserInfoContext";
import FormAvatar from "forms/components/form";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import Button from "components/ui/button/Button";
import MaskInput from "forms/components/simpleWidgets/mask-input/MaskInput";
import classes from "../ManegementForms.module.scss";
import CalendarOutline from "components/ui/icons/CalendarOutline";
import InputAuto from "components/ui/input-autocomplite/InputAutocomplite";
import { ISelectedType } from "./FormColumn";

const AVATAR_SKELETON = {
  style: {
    width: "98px",
    height: "98px",
    margin: "0 auto",
  },
  circle: true,
  containerClassName: classes["avatat-skeleton"],
};

const FormUserData = ({
  data,
  labelBtn = "Создать",
}: {
  data?: IManagementEmployeFull;
  onSucsess?: (data?: IManagementEmployeFull) => void;
  labelBtn?: string;
}) => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { userInfoContext, setUserInfoContext } = useContext(UserInfoContext);
  const refDateInput = useRef<null | HTMLDivElement>(null);
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);
  const { apiPut } = useApi();

  const onSearchActive = (
    type: "add" | "sub" | "clear",
    value?: ISelectedType<any> | null,
  ) => {
    if (type === "add" && value) {
      setSearch(value ? value.label : "");
    } else if (type === "sub" && value) {
      setSearch(value ? value.label : "");
    } else {
      setSearch(value ? value.label : "");
    }

    setSearch(value ? value.label : "");
  };

  const onCreateMentor = (value: string) => {
    return 0;
  };

  const onSubmitAvatar = async (files: FormDataType) => {
    if (files.data instanceof FileList) {
      const formData = new FormData();
      formData.append("photo", files.data[0]);
      const res = await apiPut(
        `${endpoints.user}${userInfoContext?.data.id}/photo/`,
        formData,
        undefined,
        true,
      );
      setResDataAfterSubmit(res);
      if (res.data && userInfoContext) {
        const newUserInfoContext = { ...userInfoContext };
        newUserInfoContext.data.photo = res.data.photo;
        setUserInfoContext?.(newUserInfoContext);
      }
    }
  };

  const avatarUrl = `${endpoints.user}${data?.id}/photo/update_form/`;

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-data">
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Личная информация</h3>
            <div className={classes.rowAvatar}>
              <div className={classes.gap}>
                <div className={classes.fields}>
                  <TextInput
                    name={"first_name"}
                    value={data?.first_name || ""}
                    onChange={() => void 0}
                    label="Имя"
                    theme="outline"
                    placeholder="Введите имя"
                  />
                  <TextInput
                    name={"last_name"}
                    value={data?.last_name || ""}
                    onChange={() => void 0}
                    label="Фамилия"
                    placeholder="Введите фамилию"
                    theme="outline"
                  />
                </div>
                <div className={classes.fields}>
                  <TextInput
                    name={"middle_name"}
                    value={data?.middle_name || ""}
                    onChange={() => void 0}
                    label="Отчество"
                    placeholder="Введите отчество"
                    theme="outline"
                  />
                  <div className={classes.filedData}>
                    <span className={classes.label}>Дата рождения</span>
                    <div className={classes.dateInputWrap} ref={refDateInput}>
                      <DateInput
                        name={"birthday"}
                        value={data?.birthday || ""}
                        onChange={() => void 0}
                        placeholder=""
                        theme="outline"
                      />
                      <button
                        type="button"
                        className={classes.calendarBrn}
                        onClick={() => {
                          if (refDateInput.current && refDateInput) {
                            refDateInput.current
                              .querySelector("input")
                              ?.focus();
                          }
                        }}
                      >
                        <CalendarOutline />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.formAvatarWrap + " form-avatar-wrap"}>
                {data ? (
                  <FormAvatar
                    apiUrl={avatarUrl}
                    classValueForm={classes.formAvatar}
                    classNameFields={classes.fieldAvatar}
                    onSubmit={onSubmitAvatar}
                    formId={"userAvatarForm"}
                    showBtn={false}
                    resDataAfterSubmit={resDataAfterSubmit}
                    skeleton={AVATAR_SKELETON}
                  />
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Контактные данные</h3>
            <div className={classes.fields}>
              <TextInput
                name={"email"}
                value={data?.email || ""}
                onChange={() => void 0}
                label="Почта"
                placeholder="Введите Почту"
                theme="outline"
              />
              <MaskInput
                name={"phone"}
                value={data?.phone || ""}
                onChange={() => void 0}
                label="Телефон"
                placeholder="+7 000 000 00 00"
                theme="outline"
                mask={{
                  replacement: { _: /\d/ },
                  mask: "+7 ___ ___-__-__",
                }}
              />
              <TextInput
                name={"tg"}
                value={data?.tg || ""}
                onChange={() => void 0}
                label="Мессенджер"
                placeholder="@nickname"
                theme="outline"
              />
            </div>
          </div>
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Должность и грейд</h3>
            <div className={classes.fields}>
              <SelectWrapper
                className={classes.select}
                name={"position"}
                value={data?.position || "all"}
                onChange={() => void 0}
                label="Должность"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Все", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <SelectWrapper
                className={classes.select}
                name={"role"}
                value={data?.role || "all"}
                onChange={() => void 0}
                label="Роль в системе"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Все", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <SelectWrapper
                className={classes.select}
                name={"level"}
                value={data?.level || "all"}
                onChange={() => void 0}
                label="Грейд"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Выберите из списка", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
            </div>
          </div>
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Подразделение</h3>
            <div className={classes.fields}>
              <SelectWrapper
                className={classes.select}
                name={"position"}
                value={data?.position || "all"}
                onChange={() => void 0}
                label="Компания"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Все", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <SelectWrapper
                className={classes.select}
                name={"role"}
                value={data?.role || "all"}
                onChange={() => void 0}
                label="Подразделение"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Все", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <SelectWrapper
                className={classes.select}
                name={"level"}
                value={data?.level || "all"}
                onChange={() => void 0}
                label="Отдел"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Выберите из списка", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
            </div>
          </div>
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Форма работы</h3>
            <div className={classes.fields}>
              <SelectWrapper
                className={classes.select}
                name={"level"}
                value={data?.level || "all"}
                onChange={() => void 0}
                label="Тип найма"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Выберите из списка", label: "all", value: "all" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <SelectWrapper
                className={classes.select}
                name={"level"}
                value={data?.level || "all"}
                onChange={() => void 0}
                label="Часовой пояс"
                placeholder="Выберите из списка"
                theme="outline"
                choices={[
                  { name: "Выберите из списка", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                  { name: "Все 2", label: "all2", value: "all2" },
                  { name: "Белгород", label: "bel2", value: "22" },
                ]}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                menuPortalTarget="unset"
              />
              <div>
                <span className={classes.label}>Рабочий график</span>
                <div className={classes.time}>
                  <div className={classes.timeWrap}>
                    <span className={`${classes.label} ${classes.small}`}>
                      c
                    </span>
                    <MaskInput
                      name="from"
                      value={data?.phone || ""}
                      onChange={() => void 0}
                      theme="outline"
                      placeholder="__:__"
                      className={classes.inputTime}
                      mask={{
                        replacement: {
                          a: /[0-2]/,
                          b: /[0-9]/,
                          c: /[0-5]/,
                          d: /[0-9]/,
                        },
                        mask: "ab:cd",
                      }}
                    />
                  </div>
                  <div className={classes.timeWrap}>
                    <span className={`${classes.label} ${classes.small}`}>
                      по
                    </span>
                    <MaskInput
                      name="to"
                      value=""
                      onChange={() => void 0}
                      theme="outline"
                      placeholder="__:__"
                      className={classes.inputTime}
                      mask={{
                        replacement: {
                          a: /[0-2]/,
                          b: /[0-9]/,
                          c: /[0-5]/,
                          d: /[0-9]/,
                        },
                        mask: "ab:cd",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.formItemRow}>
            <h3 className={classes.subtitle}>Ментор</h3>
            <div className={`${classes.fields}`}>
              <InputAuto<any>
                placeholder="Введите имя"
                selected={[]}
                suggestions={[]}
                search={search}
                onChange={(e) => setSearch(e.target.value)}
                onSelected={onSearchActive}
                onCreate={onCreateMentor}
                autocomplite={true}
              />
            </div>
          </div>
        </div>
      </form>
      <div className={classes.actions}>
        <Button type="button" clazz={classes.button} theme="fill">
          {labelBtn}
        </Button>
      </div>
    </div>
  );
};

export default FormUserData;
