import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import { useStateRequest } from "hooks/useStateRequest/useStateRequest";
import {
  useContext,
  useEffect,
  useMemo,
  createContext,
  ReactNode,
  useRef,
  useState,
} from "react";
import { IEmplyeePositionGrade, IEmplyeeGrade, IEmplyeePosition } from "types";

export interface IGradeAndPositions extends IEmplyeeGrade {
  positions: IEmplyeePositionGrade[];
}

const QualificationFormUserLevelContext = createContext<{
  isLoadingPositions: boolean;
  setLoadingPositions: (value: boolean) => void;
  positions: IEmplyeePosition[];
  setPositions: React.Dispatch<React.SetStateAction<IEmplyeePosition[]>>;
  grades: IEmplyeeGrade[];
  setGrades: React.Dispatch<React.SetStateAction<IEmplyeeGrade[]>>;
  isLoadingPositionGrades: boolean;
  setLoadingPositionGrades: (value: boolean) => void;
  positionGrades: IEmplyeePositionGrade[];
  setPositionGrades: React.Dispatch<
    React.SetStateAction<IEmplyeePositionGrade[]>
  >;
  isLoadingGrades: boolean;
  setLoadingGrades: (value: boolean) => void;
  getGrades: (
    search?: URLSearchParams,
    position?: string,
  ) => Promise<IEmplyeeGrade[]>;
  getPositionGrades: () => Promise<IEmplyeePositionGrade[]>;
  getPositions: (
    search?: URLSearchParams,
    category?: string | number,
  ) => Promise<IEmplyeePosition[]>;
} | null>(null);

export const QualificationFormUserLevelContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    dataRequest: positions,
    setDataRequest: setPositions,
    loading: isLoadingPositions,
    setLoading: setLoadingPositions,
  } = useStateRequest<IEmplyeePosition[]>({ initData: [] });
  const {
    dataRequest: positionGrades,
    setDataRequest: setPositionGrades,
    loading: isLoadingPositionGrades,
    setLoading: setLoadingPositionGrades,
  } = useStateRequest<IEmplyeePositionGrade[]>({ initData: [] });

  const {
    dataRequest: grades,
    setDataRequest: setGrades,
    loading: isLoadingGrades,
    setLoading: setLoadingGrades,
  } = useStateRequest<IEmplyeeGrade[]>({ initData: [] });

  const { apiGet } = useApi();

  const getPositionGrades = async (): Promise<IEmplyeePositionGrade[]> => {
    try {
      const response = await apiGet(`${endpoints.employeePositionGrade}`);
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getGrades = async (
    search?: URLSearchParams,
    position?: string,
  ): Promise<IEmplyeeGrade[]> => {
    try {
      const response = await apiGet(
        `${process.env.REACT_APP_API_URL}api/employee/grade/`,
        search,
      );
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getPositions = async (
    search?: URLSearchParams,
    category?: string | number,
  ): Promise<IEmplyeePosition[]> => {
    try {
      const response = await apiGet(
        `${endpoints.employeePosition}${
          category ? "?category=" + category : ""
        }`,
        search,
      );
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    setLoadingPositions(true);
    setLoadingGrades(true);
    setLoadingPositionGrades(true);
    Promise.all([getPositions(), getGrades(), getPositionGrades()])
      .then((res) => {
        const [_positions, _grades, _positioGrades] = res;
        setPositions(_positions);
        setGrades(_grades);
        setPositionGrades(_positioGrades);
      })
      .finally(() => {
        setLoadingPositions(false);
        setLoadingGrades(false);
        setLoadingPositionGrades(false);
      });
  }, []);

  return (
    <QualificationFormUserLevelContext.Provider
      value={{
        positions,
        setPositions,
        isLoadingPositions,
        setLoadingPositions,
        grades,
        setGrades,
        isLoadingGrades,
        setLoadingGrades,
        getGrades,
        getPositions,
        positionGrades,
        setPositionGrades,
        isLoadingPositionGrades,
        setLoadingPositionGrades,
        getPositionGrades,
      }}
    >
      {children}
    </QualificationFormUserLevelContext.Provider>
  );
};

export const useQualificationFormUserLevelLogic = () => {
  const context = useContext(QualificationFormUserLevelContext);
  const init = useRef<boolean>(false);
  const [activePosition, setActivePosition] = useState<null | IEmplyeePosition>(
    null,
  );
  const { apiDelete, apiPatch, apiPost } = useApi();

  if (!context) {
    throw new Error("QualificationFormUserLevelContext was not provided");
  }

  const {
    positions,
    isLoadingPositions,
    isLoadingGrades,
    positionGrades,
    setLoadingPositions,
    setLoadingGrades,
    setPositions,
    setPositionGrades,
    isLoadingPositionGrades,
    setGrades,
  } = context;

  const setLoadingAll = (value: boolean) => {
    setLoadingGrades(value);
    setLoadingPositions(value);
  };

  const onDeletePosition = async (value: IEmplyeePosition) => {
    try {
      await apiDelete(`${endpoints.employeePosition}${value.id}/`);

      setPositions((prev) => prev.filter((item) => item.id !== value.id));

      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onDeletePositionGrade = async (value: IEmplyeePositionGrade) => {
    try {
      await apiDelete(`${endpoints.employeePositionGrade}/${value.id}/`);

      setPositionGrades((prev) => prev.filter((item) => item.id !== value.id));

      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onUpdatePosition = async (value: {
    title: string;
    link: string;
    data: IEmplyeePosition;
  }): Promise<IEmplyeePosition> => {
    const updatePosition = (list: IEmplyeePosition[]) => {
      const arr = [...list];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === value.data.id) {
          arr[i].responsibilities_link = value.link;
          arr[i].name = value.title;
          break;
        }
      }
      return arr;
    };

    try {
      const respnse = await apiPatch(
        `${endpoints.employeePosition}/${value.data.id}/`,
        {
          responsibilities_link: value.link || "",
          name: value.title,
        },
      );

      setPositions(updatePosition(positions));

      return respnse.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onUpdateGrade = async (value: {
    name: string;
    level?: number;
    id: number;
  }): Promise<IEmplyeeGrade> => {
    const updateGrade = (list: IEmplyeeGrade[]) => {
      const arr = [...list];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === value.id) {
          arr[i].name = value.name;
          break;
        }
      }
      return arr;
    };
    try {
      const response = await apiPatch(
        `${process.env.REACT_APP_API_URL}api/employee/grade/${value.id}/`,
        {
          name: value.name,
        },
      );

      setGrades(updateGrade(context.grades));

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onUpdatePositionGrade = async (
    id: number,
    value: {
      name?: string;
      education_link: string;
      possitionGrtadeId: number;
      gradeId: number;
    },
  ): Promise<IEmplyeePositionGrade> => {
    const updatePositionGrade = (list: IEmplyeePositionGrade[]) => {
      const arr = [...list];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          arr[i].education_link = value.education_link;
          // arr[i].position = value.possitionGrtadeId;
          // arr[i].grade = value.gradeId;
          break;
        }
      }
      return arr;
    };

    try {
      if (value.name) {
        await onUpdateGrade({ name: value.name, id: value.gradeId });
      }

      const response = await apiPatch(
        `${endpoints.employeePositionGrade}/${id}/`,
        {
          education_link: value.education_link,
          grade: value.gradeId,
          position: value.possitionGrtadeId,
        },
      );

      setPositionGrades(updatePositionGrade(positionGrades));

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onCreateGrade = async (values: {
    name: string;
    level?: number;
  }): Promise<IEmplyeeGrade> => {
    try {
      const response = await apiPost(
        `${process.env.REACT_APP_API_URL}api/employee/grade/`,
        {
          name: values.name,
        },
      );
      setGrades((prev) => [response.data, ...prev]);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onCreatePositionGrade = async (
    values: {
      position: number;
      name: string;
      education_link?: string;
    },
    gradeСonnectionID?: number,
  ): Promise<IEmplyeePositionGrade> => {
    try {
      let gradeID = gradeСonnectionID;

      if (gradeСonnectionID === undefined) {
        gradeID = (await onCreateGrade({ name: values.name })).id;
      }

      const response = await apiPost(`${endpoints.employeePositionGrade}/`, {
        name: values.name,
        grade: gradeID,
        position: values.position,
        education_link: values?.education_link || "",
      });
      setPositionGrades((prev) => [response.data, ...prev]);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onCreatePosition = async (values: {
    name: string;
    responsibilities_link?: string;
  }): Promise<IEmplyeePosition> => {
    try {
      const response = await apiPost(`${endpoints.employeePosition}`, {
        name: values.name,
        responsibilities_link: values?.responsibilities_link || "",
      });

      setPositions((prev) => [response.data, ...prev]);

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    init.current = true;
  }, []);

  const isLoadingAll = useMemo(() => {
    return isLoadingPositions || isLoadingGrades || isLoadingPositionGrades;
  }, [isLoadingPositions, isLoadingGrades, isLoadingPositionGrades]);

  const gradesFiltredPosition = useMemo(() => {
    return activePosition === null
      ? context.positionGrades
      : context.positionGrades.filter(
          (item) => item.position === activePosition.id,
        );
  }, [activePosition, context.positions, context.grades]);

  const gradesTitleKeys = useMemo(() => {
    const titles: { [key: number]: string } = {};
    context.grades.forEach((item) => {
      titles[item.id] = item.name;
    });
    return titles;
  }, [context.positionGrades, context.grades]);

  const gradesAdvanced = useMemo(() => {
    const _grades: IGradeAndPositions[] = [];
    context.grades.forEach((item) => {
      const positions = positionGrades.filter(
        (position) => position.grade === item.id,
      );
      _grades.push({
        ...item,
        positions: positions,
      });
    });
    return _grades;
  }, [context.positionGrades, context.grades]);

  const gradesAdvancedPositionActive = useMemo(() => {
    return activePosition === null
      ? gradesAdvanced
      : gradesAdvanced.filter(
          (item) =>
            !!item.positions.find((pos) => pos.position === activePosition.id),
        );
  }, [
    activePosition,
    context.positions,
    context.grades,
    gradesAdvanced,
    context.positionGrades,
  ]);

  return {
    ...context,
    isLoadingAll,
    setLoadingAll,
    onDeletePosition,
    onDeletePositionGrade,
    onCreatePosition,
    onCreatePositionGrade,
    activePosition,
    setActivePosition,
    onUpdateGrade,
    onUpdatePosition,
    gradesFiltredPosition,
    gradesTitleKeys,
    gradesAdvanced,
    gradesAdvancedPositionActive,
    onCreateGrade,
    onUpdatePositionGrade,
    //onGetCompetencesByCategory,
    //onSetCategoryOfCompetence,
  };
};
